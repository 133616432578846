import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'iris-fill-loader',
  templateUrl: './fill-loader.component.html',
  styleUrls: ['./fill-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisFillLoaderComponent implements ILoadingOverlayAngularComp {
  @Input() text: string;

  agInit(params: ILoadingOverlayParams & {[key: string]: string}): void {
    this.text = params.text;
  }
}
