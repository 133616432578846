<iris-modal-content
  [isHideCross]="true"
  [isHideCloseBtn]="data.isHideCloseBtn"
  [parent]="this"
  [cancelBtnText]="data.cancelBtnText ?? 'label.Cancel'"
  confirmByKeypress
  (confirmed)="confirm()"
  (cancelled)="closeWithoutResult()"
>
  <ng-container id="iris-modal-body">
    <p>{{ (data.message ?? 'message.DeleteItemConfirm') | translate }}</p>
  </ng-container>

  <ng-container id="iris-modal-footer-buttons">
    <button
      type="button"
      class="btn btn-success"
      [disabled]="data.disableConfirmBtn"
      (click)="confirm()"
      data-test="confirm-button"
      id="Test-Confirm-Button">
        <i [class]="data.confirmBtnIconClass ?? 'far fa-check'"></i>
        <span>{{ (data.confirmBtnText ?? 'label.Ok') | translate }}</span>
    </button>
  </ng-container>
</iris-modal-content>
