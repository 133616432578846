import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError, RedirectRequest } from '@azure/msal-browser';
import { catchError, concatMap, map, Observable, of, switchMap, take } from 'rxjs';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';
import { getAzureScopes } from './msal.config';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';

@Injectable()
export class IrisMsalService {
  constructor(
    private readonly msalService: MsalService,
    private readonly authFacade: AuthFacade,
    private readonly authService: IrisAuthService,
  ) { }

  handleAzureAccount(): Observable<boolean> {
    return this.msalService.initialize().pipe(
      concatMap(() => this.msalService.handleRedirectObservable()),
      take(1),
      map(() => !!this.checkAndSetActiveAccount()),
      switchMap((res) => {
        if (!res) {
          this.msalService.loginRedirect();
        }
        return of(res);
      }),
      catchError((err) => {
        console.error('msalService.handleRedirectObservable():', err);
        return of(false);
      }),
    );
  }

  checkAndSetActiveAccount(): AccountInfo {
    if (!this.msalService.instance.getActiveAccount() && this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
    }

    return this.msalService.instance.getActiveAccount();
  }

  acquireTokenRedirect(): Observable<boolean> {
    return this.msalService.initialize().pipe(
      concatMap(() => this.msalService.acquireTokenRedirect({
        scopes: getAzureScopes(this.authService),
        redirectUri: this.authService.getMsalRedirectUri(),
      })),
      map(() => true),
      catchError((err) => {
        console.error('msalService.acquireTokenRedirect():', err);
        return of(false);
      }),
    );
  }

  acquireTokenSilent(_request?: RedirectRequest): Observable<boolean> {
    const activeAccount = this.msalService.instance.getActiveAccount();

    return this.msalService.initialize().pipe(
      concatMap(() => this.msalService.acquireTokenSilent({
        scopes: getAzureScopes(this.authService),
        redirectUri: this.authService.getMsalRedirectUri(),
        account: activeAccount,
      })),
      switchMap((res: AuthenticationResult) => this.authFacade.loginAzure(res.accessToken).pipe(
        map(() => true),
      )),
      catchError((err) => {
        console.error('msalService.acquireTokenSilent():', err);
        return err instanceof InteractionRequiredAuthError
          ? this.acquireTokenRedirect()
          : of(false);
      }),
    );
  }

  removeActiveAccount(): void {
    const activeAccount = this.checkAndSetActiveAccount();
    if (!!activeAccount) {
      this.msalService.instance.setActiveAccount(null);
    }
  }
}
