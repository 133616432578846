import { Actions, ofType } from '@ngrx/effects';
import { take, tap, map, distinctUntilChanged } from 'rxjs/operators';
import { Action, createAction } from '@ngrx/store';
import { Observable, combineLatest, OperatorFunction } from 'rxjs';
import { ActionCreator, ActionCreatorProps, NotAllowedCheck } from '@ngrx/store/src/models';
import cloneDeep from 'lodash/cloneDeep';

export function onOperationComplete<T extends Action>(actions$: Actions, successType: string, errorType: string): Promise<unknown> {
  return new Promise<T>((resolve, reject) => {
    actions$.pipe(
      ofType<T>(successType, errorType),
      take(1),
      tap((action) => {
        if (action.type === errorType) {
          reject();
        } else { resolve(action); }
      }),
    ).subscribe();
  });
}

export function muteFirst<T, R>(first$: Observable<T>, second$: Observable<R>): Observable<R> {
  return combineLatest([first$, second$]).pipe(
    map(([_, b]: [T, R]): R => b),
    distinctUntilChanged(),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createLoaderStartAction<T extends string, P extends Record<string, any>>(
  loaderStartContainerSelector: string,
  type: T,
  config?: ActionCreatorProps<P> & NotAllowedCheck<P>,
): ActionCreator<T, (props?: P) => P & Action<T>> {
  return createLoaderAction('loaderStartContainerSelector', loaderStartContainerSelector, type, config);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createLoaderStopAction<T extends string, P extends Record<string, any>>(
  loaderStopContainerSelector: string,
  type: T,
  config?: ActionCreatorProps<P> & NotAllowedCheck<P>,
): ActionCreator<T, (props?: P) => P & Action<T>> {
  return createLoaderAction('loaderStopContainerSelector', loaderStopContainerSelector, type, config);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createLoaderAction<T extends string, P extends Record<string, any>>(selectorProperty: string, selectorValue: string, type: T, config?: ActionCreatorProps<P> & NotAllowedCheck<P>): ActionCreator<T, (props?: P) => P & Action<T>> {
  const baseCreator = createAction(type, config);
  const creator = (props?: P & NotAllowedCheck<P>) => {
    return Object.assign(baseCreator(props), { [selectorProperty]: selectorValue });
  };
  return Object.defineProperty(Object.defineProperty(creator, 'type', {
    value: type,
    writable: false,
  }), selectorProperty, {
    value: selectorValue,
    writable: false,
  }) as unknown as ActionCreator<T, (props?: P) => P & Action<T>>;
}

export function cloneResult<T>(): OperatorFunction<T, T> {
  return map((value: T) => cloneDeep(value));
}
