import {
  routeToEditPage,
  routeToMembersPage,
  routeToPermissionRequestsPage,
} from '@iris/modules/projects/details/iris-project-details.route-pathes';
import {
  routeToMailboxIntegrationPage,
  routeToProductsLinkingPage,
  routeToDropboxIntegrationPage,
  routeToDmsSettingsPage,
  routeToSharedEmailClient,
  routeToFormsImportDataPage,
  routeToFormsCopyDataToProjectsPage,
  routeToMailboxIntegrationLogsPage,
} from '@iris/modules/projects/details/settings/settings.route-paths';
import { Categories } from '@iris/modules/projects/models/categories';

const moduleRoute = Object.freeze(['/', 'projects']);

export class IrisProjectsRouting {
  static getLinkToProjectList(category: Categories = null): unknown[] {
    const link = [...moduleRoute];

    if (category) {
      link.push(category);
    }

    return link;
  }

  static getLinkToCreateProjectPage(): unknown[] {
    return [...moduleRoute, 'new'];
  }

  static getLinkToProjectView(projectId: number): unknown[] {
    return [...moduleRoute, projectId];
  }

  static getLinkToProjectEdit(projectId: number): unknown[] {
    return [...moduleRoute, projectId, routeToEditPage];
  }

  static getLinkToProjectMembers(projectId: number): unknown[] {
    return [...moduleRoute, projectId, routeToMembersPage];
  }

  static getLinkToProjectPermissionRequests(projectId: number): unknown[] {
    return [...moduleRoute, projectId, routeToPermissionRequestsPage];
  }

  static getLinkToProjectMailboxIntegration(projectId: number): unknown[] {
    return [...IrisProjectsRouting.getLinkToProjectEdit(projectId), routeToMailboxIntegrationPage];
  }

  static getLinkToProjectMailboxIntegrationLogs(projectId: number): unknown[] {
    return [...IrisProjectsRouting.getLinkToProjectEdit(projectId), routeToMailboxIntegrationLogsPage];
  }

  static getLinkToProjectDmsSettings(projectId: number): unknown[] {
    return [...IrisProjectsRouting.getLinkToProjectEdit(projectId), routeToDmsSettingsPage];
  }

  static getLinkToProjectProductsLinking(projectId: number): unknown[] {
    return [...IrisProjectsRouting.getLinkToProjectEdit(projectId), routeToProductsLinkingPage];
  }

  static getLinkToProjectFormDataImport(projectId: number): unknown[] {
    return [...IrisProjectsRouting.getLinkToProjectEdit(projectId), routeToFormsImportDataPage];
  }

  static getLinkToProjectFormDataCopyToProjects(projectId: number): unknown[] {
    return [...IrisProjectsRouting.getLinkToProjectEdit(projectId), routeToFormsCopyDataToProjectsPage];
  }

  static getLinkToProjectDropboxIntegration(projectId: number): unknown[] {
    return [...IrisProjectsRouting.getLinkToProjectEdit(projectId), routeToDropboxIntegrationPage];
  }

  static getLinkToProjectSharedEmailClient(projectId: number): unknown[] {
    return [...IrisProjectsRouting.getLinkToProjectEdit(projectId), routeToSharedEmailClient];
  }
}
