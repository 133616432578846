import prodJSON from './assets/configs/prod.json';
import { environment } from 'environments/environment';

export function createEnvironment(params: typeof prodJSON): IrisEnv {
  const clientApiPath = params.clientApiPath || '/restful';

  return {
    serverUrl: params.serverURL || 'http://localhost:8080',
    apiUrl: params.serverURL + clientApiPath,
    version: environment.version,
    instanceId: params.instanceId,
    instanceName: params.instanceName || 'local',
    ignoreLocalhostRedirect: params.ignoreLocalhostRedirect === 'true',
    msal: {
      clientId: params.azureClientId,
      tenantId: params.azureTenantId,
      scope: params.azureScope,
    },
    sentryActivated: !!params.sentryDsn,
    sentryFrontendDsn: params.sentryFrontendDsn || 'https://f5e13e183d6d45dfb57d4bb094250751@o233979.ingest.sentry.io/5889134',
    rumEnvironment: params.rumEnvironment || 'localhost',
    rumActivated: !!params.rumUrl,
    rumUrl: params.rumUrl,
    rumLogLevel: params.rumLogLevel as LogLevel || 'info',
    supportITCEmail: params.ITCSupportEmail || 'support.iris@strabag.com',
    gmapsApiKey: params.gmapsApiKey || 'AIzaSyAEH0f-VMMVkUCQUPtzMPObZJ8YFObB3Uo',
    redirectMicroservices: (params.redirectMicroservices || '').split('|').map(t => t.trim()).filter(t => !!t),
    dropboxIntegrationActivated: params.dropboxIntegrationActivated === 'true',
    formsEnabled: params.formsEnabled === 'true',
    unleashProxyURL: params.unleashProxyURL || `${params.serverURL}/ui/ui2/assets/configs/unleash/proxy.json`.replace(/\/+/g, '\/'),
    unleashProxyKey: params.unleashProxyKey || 'clientKey',
  };
}
