import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
import { ConfirmAlertOptions } from '../../model/ConfirmParamsModel';

/**
 * This component has been marked as @deprecated as we have moved to a new Confirm Modal Component: https://jira.strabag.io/browse/IRIS-34129
 * When calling the confirmation dialog, make sure that the isLegacy flag in your component is not set to 'true'.
 */

@Component({
  selector: 'legacy-confirm-modal',
  styleUrls: ['./legacy-confirm-modal.component.scss'],
  templateUrl: './legacy-confirm-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegacyConfirmComponent extends IrisModalBase {
  constructor(
    public dialogRef: MatDialogRef<LegacyConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<ConfirmAlertOptions>,
  ) {
    super(dialogRef);
  }

  confirm(): void {
    this.closeWithResult(true);
  }

}
