<header mat-dialog-title
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true" 
  class="d-flex align-items-center border-bottom">
  <h3 class="flex-col-auto mb-0">
    @if (data.isCritical) {
      <i class="fas warn-icon fa-exclamation-triangle pe-2"></i>
      <span class="text-gray text-rich">{{ (data.headerTitleText ?? 'label.CriticalConfirmTitle') | translate }}</span>
    } @else {
      <i class="far fa-exclamation-circle pe-2 grey"></i>
      <span class="text-gray text-rich">{{ (data.headerTitleText ?? 'label.ConfirmTitle') | translate }}</span>
    }
  </h3>

  <button mat-icon-button
    cdkFocusInitial
    class="close-button"
    data-test="critical-confirm_header-cancel-button"
    [matTooltip]="'label.Cancel' | translate"
    [mat-dialog-close]="false">
      <i class="fal fa-times"></i>
  </button>
</header>

<section mat-dialog-content class="p-4 text-center">
  <p>{{ (data.message ?? 'message.DeleteItemConfirm') | translate }}</p>
</section>

<footer mat-dialog-actions
  class="pb-4"
  [align]="'center'">
  <button mat-flat-button
    [disabled]="data.disableConfirmBtn"
    [color]="(data.isCritical ? 'warn' : 'primary')"
    (click)="confirm()"
    data-test="critical-confirm_footer-confirm-button">
    @if (data.confirmBtnIconClass) {
      <i class="icon" [class]="data.confirmBtnIconClass"></i>
    }
      <span>{{ (data.confirmBtnText ?? 'label.Yes') | translate }}</span>
  </button>
  @if (!data.isHideCloseBtn) {
    <button mat-stroked-button
      [mat-dialog-close]="false"
      data-test="critical-confirm_footer-cancel-button">
        <span>{{ (data.cancelBtnText ?? 'label.Cancel') | translate }}</span>
    </button>
  }
</footer>
