import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ComponentRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy, Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { EMPTY_SIGNATURE } from '@iris/common/modules/dynamic-forms/df-prop/properties/signature/constant';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { IrisSignatureCanvasComponent } from './signature-canvas/signature-canvas.component';

@Component({
  selector: 'iris-signature-field',
  templateUrl: './signature-field.component.html',
  styleUrls: ['./signature-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrisSignatureFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisSignatureFieldComponent implements ControlValueAccessor, OnDestroy {
  @Input() set width(width: number) {
    if (!width) { return; }
    this._width = width;
  }
  get width(): number {
    return this._width;
  }

  @Input() set height(height: number) {
    if (!height) { return; }
    this._height = height;
  }
  get height(): number {
    return this._height;
  }

  private _width = 568;
  private _height = 220;
  previewWidth = 150;
  previewHeight = this.height*this.previewWidth/this.width;

  @Input() disabled = false;
  @Input() readonly = false;
  @Output() focusEmitter = new EventEmitter<boolean>();

  signatureImg: string = EMPTY_SIGNATURE;
  propagateChange: Function = (): void => {};
  propagateBlur: Function = (): void => {};

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly sanitizer: DomSanitizer,
    private readonly overlay: Overlay,
  ) {}

  get isEditMode(): boolean {
    return !this.disabled && !this.readonly;
  }

  get isEmpty(): boolean {
    return !this.signatureImg;
  }

  get emptySignatureImg(): string {
    return EMPTY_SIGNATURE;
  }

  clearPad(): void {
    this.signatureImg = null;
    this.changeDetector.markForCheck();
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      this.signatureImg = value || EMPTY_SIGNATURE;
      this.changeDetector.markForCheck();
    }
  }

  registerOnChange(fn: Function): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.propagateBlur = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    this.changeDetector.markForCheck();
  }

  displayOverlay(): void {
    if(!this.isEditMode) {
      return;
    }
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      panelClass: 'signature-field-overlay',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
    const component = new ComponentPortal(IrisSignatureCanvasComponent);
    const componentRef: ComponentRef<IrisSignatureCanvasComponent> = overlayRef.attach(component);

    componentRef.instance.width = this.width;
    componentRef.instance.height = this.height;
    componentRef.instance.signature = this.signatureImg;

    componentRef.instance.focusEmitter.pipe(
      tap((focus) => this.focusEmitter.emit(focus)),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    componentRef.instance.changeEmitter.pipe(
      tap((signatureImg) => {
        this.signatureImg = signatureImg;
        this.propagateChange(signatureImg);
        this.changeDetector.markForCheck();
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    componentRef.instance.cancelEmitter.pipe(
      tap(() => overlayRef.detach()),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    overlayRef.backdropClick().pipe(
      tap(() => overlayRef.detach()),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
