import { AfterViewInit, Directive, EventEmitter, Inject, Input, OnChanges, OnDestroy, Optional } from '@angular/core';
import { NgSelectComponent } from '@natlex/ng-select';
import { isNil, omitBy } from 'lodash';
import { isObservable, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { pairwise, share, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IrisNgSelectFieldSearchEngine, SEARCH_ENGINE } from './search-engine';
import {
  ADD_TAG,
  AUTO_OPEN,
  BIND_LABEL,
  BIND_VALUE,
  DROPDOWN_FOOTER_TEMPLATE,
  DROPDOWN_HEADER_TEMPLATE,
  EDITABLE_SEARCH_TERM,
  GROUP_BY,
  ITEMS,
  LABEL_TEMPLATE,
  MULTIPLE,
  OPTGROUP_TEMPLATE,
  OPTION_TEMPLATE,
  PLACEHOLDER,
  SELECTABLE_GROUP,
  SELECTABLE_GROUP_AS_MODEL,
} from './properties-tokens';
import { NG_SELECT_EVENT_HANDLER, NgSelectEvent, NgSelectEventHandler } from '@iris/common/modules/fields/ng-select-field/ng-select-event-handler';
import { NG_SELECT_EVENTS_PROP } from '@iris/common/modules/fields/ng-select-field/decorators';
import { emptyIrisPage } from '@iris/common/models/page';

const DEFAULT_NEED_SCROLL_TO_TOP = <TItem>(prev: TItem[], curr: TItem[]): boolean => {
  return prev?.length > curr?.length;
};

@Directive({
  selector: 'ng-select',
  exportAs: 'irisNgSelectField',
})
export class IrisNgSelectFieldDirective implements OnChanges, OnDestroy, AfterViewInit {
  @Input() dropdownWidth: 'auto' | null;

  readonly _destroyed = new Subject<void>();

  constructor(
    private readonly select: NgSelectComponent,
    @Optional() @Inject(SEARCH_ENGINE) private readonly searchEngine?: IrisNgSelectFieldSearchEngine<unknown, unknown>,
    @Optional() @Inject(DROPDOWN_HEADER_TEMPLATE) headerTemplate?: NgSelectComponent['headerTemplate'],
    @Optional() @Inject(DROPDOWN_FOOTER_TEMPLATE) footerTemplate?: NgSelectComponent['footerTemplate'],
    @Optional() @Inject(LABEL_TEMPLATE) labelValueTemplate?: NgSelectComponent['labelValueTemplate'],
    @Optional() @Inject(OPTION_TEMPLATE) optionTemplate?: NgSelectComponent['optionTemplate'],
    @Optional() @Inject(BIND_LABEL) bindLabel?: NgSelectComponent['bindLabel'],
    @Optional() @Inject(BIND_VALUE) bindValue?: NgSelectComponent['bindValue'],
    @Optional() @Inject(GROUP_BY) groupBy?: NgSelectComponent['groupBy'],
    @Optional() @Inject(SELECTABLE_GROUP) selectableGroup?: NgSelectComponent['selectableGroup'],
    @Optional() @Inject(SELECTABLE_GROUP_AS_MODEL) selectableGroupAsModel?: NgSelectComponent['selectableGroupAsModel'],
    @Optional() @Inject(MULTIPLE) multiple?: NgSelectComponent['multiple'],
    @Optional() @Inject(PLACEHOLDER) placeholder?: NgSelectComponent['placeholder'],
    @Optional() @Inject(OPTGROUP_TEMPLATE) optgroupTemplate?: NgSelectComponent['optgroupTemplate'],
    @Optional() @Inject(ITEMS) items?: NgSelectComponent['items'],
    @Optional() @Inject(ADD_TAG) addTag?: NgSelectComponent['addTag'],
    @Optional() @Inject(AUTO_OPEN) private readonly autoOpen?: boolean,
    @Optional() @Inject(EDITABLE_SEARCH_TERM) editableSearchTerm?: NgSelectComponent['editableSearchTerm'],
    @Optional() @Inject(NG_SELECT_EVENT_HANDLER) eventHandlers?: NgSelectEventHandler[],
  ) {
    const definedOptions = omitBy({
      items,
      addTag,
      bindLabel,
      bindValue,
      headerTemplate,
      footerTemplate,
      labelValueTemplate,
      optionTemplate,
      groupBy,
      selectableGroup,
      selectableGroupAsModel,
      multiple,
      placeholder,
      optgroupTemplate,
      editableSearchTerm,
    }, isNil);

    Object.assign(select, definedOptions);

    if (items) {
      select.refreshItems();
    }

    if (this.searchEngine) {
      this.select.typeahead = this.searchEngine.typeahead;
      this.select.getMissingItemLabelFn = this.searchEngine.getMissingItemLabelFn?.bind(this.searchEngine);

      const load$ = this.searchEngine.reloadOnOpen
        ? this.select.openEvent
        : this.select.focusEvent.pipe(
          take(1),
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: false,
          }),
        );

      this.searchEngine.loading$?.pipe(
        tap(loading => this.select.loading = loading),
        tap(() => setTimeout(() => this.select.detectChanges())),
        takeUntil(this._destroyed),
      ).subscribe();

      (this.searchEngine.resultOnInit ? of(null) : load$).pipe(
        switchMap(() => this.searchEngine.result$.pipe(
          this.searchEngine.reloadOnOpen ? startWith(emptyIrisPage()) : tap(),
          this.searchEngine.reloadOnOpen ? takeUntil(this.select.blurEvent) : tap(),
        )),
        startWith(null),
        pairwise(),
        tap(([prevPage, currPage]) => {
          const needScrollToTop = (this.searchEngine.needScrollToTop ?? DEFAULT_NEED_SCROLL_TO_TOP)(prevPage?.elements, currPage?.elements);
          this.select.items = currPage.elements;
          this.select.refreshItems();
          if (needScrollToTop && this.select.dropdownPanel?.items?.length) {
            this.select.dropdownPanel.scrollTo(this.select.dropdownPanel.items[0]);
          }
        }),
        takeUntil(this._destroyed),
      ).subscribe();

      const searchEngineConstructor = Object.getPrototypeOf(this.searchEngine)?.constructor;
      if (searchEngineConstructor?.[NG_SELECT_EVENTS_PROP]) {
        Object.getOwnPropertyNames(searchEngineConstructor[NG_SELECT_EVENTS_PROP]).forEach((eventName: NgSelectEvent) => {
          const props: string[] = searchEngineConstructor[NG_SELECT_EVENTS_PROP]?.[eventName];
          (this.select[eventName] as Observable<unknown>).pipe(
            tap(e => props.forEach(prop => this.searchEngine[prop]?.next?.(e))),
            takeUntil(this._destroyed),
          ).subscribe();
        });
      }
    }

    if (eventHandlers?.length) {
      eventHandlers.forEach(handler => {
        (select[handler.eventType] as EventEmitter<unknown>).pipe(
          switchMap(event => {
            const res = handler.handle(select, event);
            return isObservable(res) ? res : of(res);
          }),
          takeUntil(this._destroyed),
        ).subscribe();
      });
    }

    if (autoOpen) {
      if (searchEngine) {
        searchEngine.result$.pipe(
          take(1),
          tap(() => setTimeout(() => select.open())),
        ).subscribe();
      } else if (items) {
        setTimeout(() => { select.open(); });
      }
    }

    if (this.dropdownWidth == 'auto') {
      this._toggleClass('dropdown-width-auto');
    }

    select.clearEvent.pipe(
      tap(() => select.open()),
      takeUntil(this._destroyed),
    ).subscribe();
  }

  private _toggleClass(className: string, remove = false): void {
    const classes: string[] = !this.select.classes ? [] : this.select.classes.split(' ');
    const element: HTMLElement = this.select.element;

    element.classList.toggle(className, remove);

    if (!classes.includes(className)) {
      classes.push(className);
      this.select.classes = classes.join(' ');
    } else if (remove) {
      this.select.classes = classes.filter((cls) => cls != className).join(' ');
    }
  }

  ngOnChanges(changes: SimpleChanges<this>): void {
    if (changes.dropdownWidth) {
      this._toggleClass('dropdown-width-auto', this.dropdownWidth != 'auto');
    }
  }

  // Workaround for auto-opening
  ngAfterViewInit(): void {
    if (this.autoOpen) { setTimeout(() => this.select.open()); }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this.searchEngine?.dispose?.();
  }
}
