import { NgModule } from '@angular/core';
import { ToggleComponent } from './toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  declarations: [ToggleComponent],
  exports: [ToggleComponent],
})
export class ToggleModule { }
