/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, DoCheck, Input, OnDestroy, signal } from '@angular/core';
import { ControlValueAccessor, NgControl, NgModel, RequiredValidator, Validator, Validators } from '@angular/forms';
import { ControlRootComponent } from './control-root.component';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive()
export abstract class BaseControlAccessorComponent extends ControlRootComponent implements OnDestroy, DoCheck, ControlValueAccessor {
  @Input() label: string;
  @Input() hint: string;
  @Input() hintRight: string;

  required = signal(false);

  propagateChangeCore: (_: any) => void;
  propagateTouchedCore: () => void;

  protected validators: (Function | Validator)[];
  protected ngModelSubscription: Subscription;

  protected constructor(
    ngControl: NgControl,
    validators: (Function | Validator)[],
  ) {
    super(ngControl);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
      if (this.ngControl instanceof NgModel) { this.setupNgModel(); }
    }

    this.validators = validators || [];
  }

  ngDoCheck(): void {
    const hasValidator = this.ngControl.control.hasValidator(Validators.required);
    const foundValidator = this.validators?.find(v => v.constructor === RequiredValidator) as RequiredValidator;
    const isFoundValidatorRequired = !!foundValidator && coerceBooleanProperty(foundValidator.required);

    this.required.set(hasValidator || isFoundValidatorRequired);
  }

  protected setupNgModel(): void {
    this.ngModelSubscription = this.ngControl.valueChanges.pipe(
      tap(t => {
        if ((this.ngControl.control as unknown as { _pendingChange: boolean })._pendingChange) {
          this.ngControl.viewToModelUpdate(t);
        }
      }),
    ).subscribe();
  }

  protected propagateChange(newValue: any): void {
    if (this.outerControl?.value !== newValue) {
      this.propagateChangeCore?.(newValue);
    }
  }

  protected propagateTouched(): void {
    this.propagateTouchedCore();
  }

  registerOnChange(fn: any): void {
    this.propagateChangeCore = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouchedCore = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.ngControl.enabled) { this.ngControl.control.disable(); }
    if (!isDisabled && this.ngControl.disabled) { this.ngControl.control.enable(); }
  }

  writeValue(_obj: any): void {
  }

  ngOnDestroy(): void {
    this.ngModelSubscription?.unsubscribe();
  }
}
