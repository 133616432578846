import { Action } from '@ngrx/store';
import { IrisCompanyI } from '@iris/common/models/IrisCompany';
import { IrisLanguageI } from '../../../models/IrisLanguage';
import { IrisCountryI } from '../../../models/IrisCountry';
import { IrisUserGroupI } from '../../user-common/models/IrisUserGroup';
import { IrisQueryParams } from '@iris/api-query';

export enum InitActionTypes {
  LoadInitLanguages = '[Init] Languages load',
  InitLanguages = '[Init] Languages',
  InitCompaniesStart = '[Init] Companies Start',
  InitCompanies = '[Init] Companies',
  LoadInitUsers = '[Init] Users load',
  InitUsers = '[Init] Users',
  InitCountriesStart = '[Init] Countries Start',
  InitCountries = '[Init] Countries',
  LoadUserGroupsStart = '[Init] Load User Groups',
  LoadUserGroupsSuccess = '[Init] Load User Groups Success',
}

export class InitCompaniesStart implements Action {
  readonly type = InitActionTypes.InitCompaniesStart;
}

export class InitCompanies implements Action {
  readonly type = InitActionTypes.InitCompanies;

  constructor(public payload: IrisCompanyI[]) {
  }
}

export class LoadInitLanguages implements Action {
  readonly type = InitActionTypes.LoadInitLanguages;
}

export class InitLanguages implements Action {
  readonly type = InitActionTypes.InitLanguages;

  constructor(public payload: IrisLanguageI[]) {
  }
}

export class LoadInitUsers implements Action {
  readonly type = InitActionTypes.LoadInitUsers;

  constructor() {
  }
}

export class InitUsers implements Action {
  readonly type = InitActionTypes.InitUsers;

  constructor(public payload: IdentUserI[]) {

  }
}

export class InitCountriesStart implements Action {
  readonly type = InitActionTypes.InitCountriesStart;
}

export class InitCountries implements Action {
  readonly type = InitActionTypes.InitCountries;

  constructor(public payload: IrisCountryI[]) {
  }
}

export class LoadUserGroupsStart implements Action {
  readonly type = InitActionTypes.LoadUserGroupsStart;

  constructor(public params: IrisQueryParams = new IrisQueryParams()) {}
}

export class LoadUserGroupsSuccess implements Action {
  readonly type = InitActionTypes.LoadUserGroupsSuccess;

  constructor(public userGroups: IrisUserGroupI[]) {}
}

export type InitActions =
  | InitCompanies
  | InitLanguages
  | LoadInitLanguages
  | LoadInitUsers
  | InitUsers
  | InitCountries
  | LoadUserGroupsSuccess;
