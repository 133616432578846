import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
import { ConfirmAlertOptions } from '../../model/ConfirmParamsModel';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { IrisCommonModule } from '@iris/common/modules/common/common.module';
import { A11yModule } from '@angular/cdk/a11y';
import { tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'confirm-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    TranslateModule,
    IrisCommonModule,
    A11yModule,
  ],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent extends IrisModalBase implements OnInit {
  constructor(
    private readonly destroyRef: DestroyRef,
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<ConfirmAlertOptions>,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.dialogRef.keydownEvents().pipe(
      tap((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this._dismiss();
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private _dismiss(): void {
    this.closeWithoutResult();
  }

  confirm(): void {
    this.closeWithResult(true);
  }

}
