<canvas [width]="width"
        [height]="height"
        (focus)="focusEmitter.emit(true)"
        (focusout)="focusEmitter.emit(false)"
        #canvas
        tabindex="0"></canvas>

<mat-toolbar *ngIf="showButtons">
  <button class="clear-button btn btn-default" type="button" *ngIf="!isEmpty" (click)="clearPad()">
    {{'label.Clear' | translate}}
  </button>

  <div class="spacer"></div>

  <button class="cancel-button btn btn-default" (click)="cancel()">
    {{'label.Cancel' | translate}}
  </button>

  <button class="apply-button btn btn-success" (click)="applySignature()">
    {{'label.Apply' | translate}}
  </button>
</mat-toolbar>
