import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  HostListener,
} from '@angular/core';
import { BehaviorSubject, interval, noop, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { IrisUserService } from '@iris/common/services/user.service';
import { Overlay, OverlayRef, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { AbstractDatetime } from '@iris/common/modules/date';

@Component({
  selector: 'iris-header-clock',
  templateUrl: './header-clock.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisHeaderClockComponent implements AfterViewInit, OnInit, OnDestroy {

  clock$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  private readonly unsubscribe$: Subject<void> = new Subject();

  /* Halloween 2022 */
  private overlayRef: OverlayRef;
  private overlayPosition: GlobalPositionStrategy;
  private userActivityTimeoutId: NodeJS.Timeout = null;
  private readonly userInactive: Subject<boolean> = new Subject();

  constructor(
    private readonly zone: NgZone,
    private readonly userService: IrisUserService,
    private readonly overlay: Overlay,
  ) {}

  get timeZone(): string {
    return AbstractDatetime.nowLocal().setTimezone(this.userService.me.timeZone).format('ZZZ');
  }

  onDblClick(): void {
    if(!this.isTodayHalloween) {
      return;
    }

    this.runScreensaver();
  }

  private runScreensaver(): void {
    if (!this.overlayRef.hasAttached()) {
      this.resetIdleDetector();
    }
  }

  private getOverlayPosition(): GlobalPositionStrategy {
    this.overlayPosition = this.overlay.position().global();

    return this.overlayPosition;
  }

  private runIdleDetector(timeout = 120000): void {
    this.userActivityTimeoutId = setTimeout(() => this.userInactive.next(true), timeout);
  }

  private resetIdleDetector(): void {
    if(this.userActivityTimeoutId) {
      clearTimeout(this.userActivityTimeoutId);
    }
  }

  private get isTodayHalloween(): boolean {
    const now = new Date();
    return now.getMonth() === 9 && now.getDate() === 31;
  }

  @HostListener('window:mousemove')
  refreshUserState(): void {
    if(!this.isTodayHalloween) {
      return;
    }

    this.resetIdleDetector();
    if (!this.overlayRef.hasAttached()) {
      this.runIdleDetector();
    }
  }

  ngAfterViewInit(): void {
    if(!this.isTodayHalloween) {
      return;
    }

    this.overlayRef = this.overlay.create({
      positionStrategy: this.getOverlayPosition(),
      width: '100%',
      height: '100%',
    });

    this.runIdleDetector();
    this.userInactive.pipe(
      tap(() => {
        this.runScreensaver();
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      interval(10000).pipe(
        map(() => this.clock$.next(new Date())),
        tap(noop),
        takeUntil(this.unsubscribe$),
      ).subscribe();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
