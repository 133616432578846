import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnDestroy, Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'iris-signature-canvas',
  templateUrl: './signature-canvas.component.html',
  styleUrls: ['./signature-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisSignatureCanvasComponent implements AfterViewInit, OnDestroy {
  @ViewChild('canvas') canvasEl: ElementRef;

  @Input() set width(width: number) {
    if(this._width !== width) {
      clearTimeout(this._timeoutId);
      this._timeoutId = setTimeout(() => {
        this.resizeCanvas();
      });
    }

    if (!width) { return; }
    this._width = width;
  }
  get width(): number {
    return this._width;
  }
  private _width: number;

  @Input() set height(height: number) {
    if(this._height !== height) {
      clearTimeout(this._timeoutId);
      this._timeoutId = setTimeout(() => {
        this.resizeCanvas();
      });
    }

    if (!height) { return; }
    this._height = height;
  }
  get height(): number {
    return this._height;
  }
  private _height: number;

  private _timeoutId: NodeJS.Timeout = null;

  @Input() set signature(signature: string) {
    this.signatureImg = signature;
  }

  @Input() showButtons = true;

  @Output() focusEmitter = new EventEmitter<boolean>();
  @Output() changeEmitter = new EventEmitter<string | null>();
  @Output() cancelEmitter = new EventEmitter<void>();

  private signatureImg: string;
  private signaturePad: SignaturePad;
  private readonly unsubscribe$ = new Subject<void>();

  get isEmpty(): boolean {
    return this.signaturePad?.isEmpty();
  }

  endDrawing = (): void => {
    this.signatureImg = this.signaturePad.toDataURL();

    this.changeDetector.markForCheck();
  };

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  initCanvas(canvas: HTMLCanvasElement, signature: string): void {
    this.signaturePad = new SignaturePad(canvas);
    this.pasteSignature(signature);
    this.signaturePad.addEventListener('endStroke', this.endDrawing);
  }

  private resizeCanvas(): void {
    if(!this.canvasEl?.nativeElement) {
      return;
    }

    const canvas = this.canvasEl.nativeElement;

    canvas.width = this.width;
    canvas.height = this.height;

    this.pasteSignature(this.signatureImg);
  }

  clearPad(): void {
    this.signatureImg = null;
    this.pasteSignature(null);
  }

  private pasteSignature(signatureImg: string): void {
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    this.signaturePad.fromDataURL(signatureImg, { width: this.width, height: this.height }).catch(() => {});

    this.changeDetector.markForCheck();
  }

  applySignature(): void {
    this.changeEmitter.emit(this.signatureImg);
    this.cancel();
  }

  cancel(): void {
    this.cancelEmitter.emit();
  }

  ngAfterViewInit(): void {
    this.initCanvas(this.canvasEl.nativeElement, this.signatureImg);
  }

  ngOnDestroy(): void {
    this.signaturePad.removeEventListener('endStroke', this.endDrawing);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
