import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { bufferTime, filter, map, switchMap, withLatestFrom, mergeMap, catchError } from 'rxjs/operators';
import { select, Store, Action } from '@ngrx/store';
import { IrisProjectsService } from '@iris/common/services/projects.service';
import * as ProjectActions from '@iris/common/redux/actions/projects.actions';
import { IrisProjectTypeI } from '@iris/common/models/irisProjectType';
import { IrisQueryParams } from '@iris/api-query';
import * as fromRoot from '@iris/common/redux/reducers';
import { IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisDepartmentService } from '@iris/common/services/department.service';
import { IrisUserSettingsService } from '@iris/common/services/user-settings.service';
import { of } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { emptyIrisPage } from '@iris/common/models/page';
import { IrisProjectI } from '@iris/common/models';
import { IrisUserService, MeSettings } from '@iris/common/services/user.service';
import { IrisUserSettingsI } from '@iris/common/modules/grid-configuration/models/grid-configuration.settings';

@Injectable()
export class ProjectsEffects {
  loadProjectTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProjectTypes),
      switchMap(() => {
        const queryParams = new IrisQueryParams(
          { 'only-fields': ['id', 'name', 'nameTranslated', 'code', 'color', 'active', 'system'] },
        );
        return this.projectsService.getProjectTypes(queryParams).pipe(
          map((projectTypes: IrisProjectTypeI[]) => ProjectActions.loadProjectTypesComplete({ projectTypes })),
        );
      }),
    ),
  );

  getDepartmentPaths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadDepartmentPaths),
      map((action) => action.departmentId),
      bufferTime(100),
      withLatestFrom(this.store$.pipe(select(fromRoot.getDepartmentsIds))),
      map(([departmentsIds, inStoreDepartmentsIds]) => departmentsIds
        .filter((departmentId) => !inStoreDepartmentsIds.includes(departmentId)),
      ),
      filter((departmentsIds) => departmentsIds.length > 0),
      mergeMap((departmentsIds) => {
        return this.departmentService.getDepartmentsWithParents(new IrisQueryParamsBuilder().filter('id', departmentsIds).toStructure()).pipe(
          map((departments) => ProjectActions.loadDepartmentPathsComplete({ departments })),
        );
      }),
    ),
  );

  changeCurrentProjectId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.changeCurrentProjectId),
      map(({ projectId }) => projectId),
      switchMap((projectId) => {
        if(!projectId) return of(null);

        return this.projectsService.getProjectById(projectId).pipe(
          catchError(err => {
            if (err.status === HttpStatusCode.NotFound) {
              this.alertify.error(this.translateService.instant('label.project.deletedByAdmin'));
              this.store$.dispatch(ProjectActions.changeCurrentProjectId({ projectId: null }));
            }
            return of(null);
          }),
        );
      }),
      withLatestFrom(this.store$.pipe(select(fromRoot.getRecentProjectsIds))),
      switchMap(([project, recentProjectsIds]) => {
        const actions: Action<string>[] = [ProjectActions.setCurrentProject({ project })];
        if (project && project.id && (project.id !== recentProjectsIds[0])) {
          const projectId = project.id;
          const maxItems = 5;;
          recentProjectsIds = [].concat(recentProjectsIds);
          const removingIndex: number = recentProjectsIds.indexOf(projectId);

          if (removingIndex !== -1) {
            recentProjectsIds.splice(removingIndex, 1);
          }

          recentProjectsIds.unshift(projectId);
          recentProjectsIds.splice(maxItems);

          const updatingSettings = Object.assign({}, (this.userService.meSettings || {}), { recentProjects: recentProjectsIds });

          return this.userSettingsService
            .saveUserSettings<IrisUserSettingsI<MeSettings>>('general', this.userService.me.id, { settings: updatingSettings })
            .pipe(
              switchMap((res) => {
                this.userService.setMeSettings(res);
                actions.push(ProjectActions.setRecentProjectsIds({ projectsIds: recentProjectsIds }));
                return actions;
              }),
            );
        }
        return actions;
      }),
    ),
  );

  setRecentProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.setRecentProjectsIds),
      switchMap(() => {
        return this.globalSandbox.getRecentProjects().pipe(
          catchError(() => of(emptyIrisPage<IrisProjectI>())),
        );
      }),
      switchMap(recentProjects => {
        return [ProjectActions.setRecentProjects({ projects: recentProjects.elements })];
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<fromRoot.State>,
    private readonly projectsService: IrisProjectsService,
    private readonly departmentService: IrisDepartmentService,
    private readonly userSettingsService: IrisUserSettingsService,
    private readonly alertify: IrisAlertService,
    private readonly translateService: TranslateService,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly userService: IrisUserService,
  ) { }
}
