import { EventEmitter, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store, Action } from '@ngrx/store';
import { IrisEmailsService } from '../../services/emails.service';
import * as emailsReducer from '../index';
import {
  MarkMessageAsReadError,
  MarkMessageAsReadStart,
  MarkMessageAsReadSuccess,
  MarkMessageAsUnreadError,
  MarkMessageAsUnreadStart,
  MarkMessageAsUnreadSuccess,
  SaveMessageAsDraftError,
  SaveMessageAsDraftStart,
  ToggleMessageReadMark,
  SendOpenedDraftEmailStart,
  SendOpenedDraftEmailError,
  RemoveEmailMessageStart,
  RemoveEmailMessageSuccess,
  RemoveEmailMessageError,
  RemoveSelectedEmailMessagesStart,
  RemoveSelectedEmailMessagesSuccess,
  RemoveSelectedEmailMessagesError,
  SaveMessageToDmsStart,
  SaveMessageToDmsSuccess,
  SaveMessageToDmsError,
  SaveMessageAttachmentsToDmsStart,
  SaveMessageAttachmentsToDmsSuccess,
  SaveMessageAttachmentsToDmsError,
  MoveMessagesToFolderStart,
  MoveMessagesToFolderSuccess,
  MoveMessagesToFolderError,
  UploadMessageAttachmentsStart,
  UploadMessageAttachmentsSuccess,
  UploadMessageAttachmentsError,
  RemoveMessageAttachmentStart,
  RemoveMessageAttachmentSuccess,
  RemoveMessageAttachmentError,
  GetMessageAttachmentsStart,
  GetMessageAttachmentsSuccess,
  GetMessageAttachmentsError,
  SaveMessagesToDmsStart,
  SaveMessagesToDmsSuccess,
  SaveMessagesToDmsError,
  SaveMessageAttachmentToDmsStart,
  SaveMessageAttachmentToDmsSuccess,
  SaveMessageAttachmentToDmsError,
  SetMessageReadMarkBulkStart,
  SetMessageReadMarkBulkError,
  ForwardMessageStart,
  ForwardMessageError,
  ReplyToMessageStart,
  ReplyToMessageError,
  ReplyToAllStart,
  ReplyToAllError,
  UploadAttachmentsFromDmsStart,
  UploadAttachmentsFromDmsSuccess,
  UploadAttachmentsFromDmsError,
  CancelDmsAttachmentUpload,
  FetchMessagesRangeStart,
  AddEmailMessagesSuccess,
  EmailsMessageChangeEvent,
  GetEmailsMessageStart,
  GetEmailsMessageSuccess,
  GetEmailsMessageError,
  SetMessageReadMarkBulkSuccess,
  GetEmailsMessageConversationStart,
  GetEmailsMessageConversationSuccess,
  GetEmailsMessageConversationError,
} from './emails-messages.actions';
import { switchMap, map, catchError, of, take, filter, withLatestFrom, mergeMap, forkJoin, tap, Observable, takeUntil, debounceTime, buffer } from 'rxjs';
import { GetUnreadCountStart, ResetMessagesPagination, ResetMessagesSelection, SetOpenedDraftMessage, SetTotalItemsCount } from '../global/emails-global.actions';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { EmailMessageChangeEventI, EmailMesssageChangeActionType, IrisEmailMessage } from '../../models/IrisEmail';
import { RefreshCustomFoldersStart, GetCustomFolderStart } from '../navigation/emails-navigation.actions';
import { IrisEmailsRoutingService } from '@iris/modules/emails/services/emails-routing.service';
import { IrisDMSFileI } from '@iris/common/modules/dms/models/IrisDMSFile';
import { isEqual, isNil, isNumber, uniq, uniqWith } from 'lodash';
import { EmailWellKnownFolderName } from '../../models/IrisEmailNavigation';

@Injectable()
export class IrisEmailsMessagesEffects {
  markMessageAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkMessageAsReadStart),
      mergeMap(action => this.emailsService.markMessageAsRead(action.messageId).pipe(
        map(message => MarkMessageAsReadSuccess({ message })),
        catchError(() => of(MarkMessageAsReadError())),
      )),
    ),
  );

  markMessageAsUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkMessageAsUnreadStart),
      mergeMap(action => this.emailsService.markMessageAsUnread(action.messageId).pipe(
        map(message => MarkMessageAsUnreadSuccess({ message })),
        catchError(() => of(MarkMessageAsUnreadError())),
      )),
    ),
  );

  toggleMessageReadMark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToggleMessageReadMark),
      mergeMap(action => this.store.pipe(select(emailsReducer.getMessageById(action.messageId)), filter(Boolean), take(1))),
      map(message => {
        if (message.markedAsRead) {
          return MarkMessageAsUnreadStart({ messageId: message.id });
        }
        return MarkMessageAsReadStart({ messageId: message.id });
      }),
    ),
  );

  setMessageReadMarkBulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetMessageReadMarkBulkStart),
      switchMap(({ read }) => this.getBulkActionParams().pipe(
        map(params => ({ ...params, read })),
      )),
      switchMap(params => {
        return this.emailsService.setMessagesReadMarkBulk(
          params.selectedFolderId,
          params.read,
          params.selectedMessagesIds,
          params.unselectedMessagesIds,
        ).pipe(
          switchMap(() => [
            ResetMessagesSelection(),
            SetMessageReadMarkBulkSuccess({
              read: params.read,
              selectedMessagesIds: params.selectedMessagesIds,
              unselectedMessagesIds: params.unselectedMessagesIds,
            }),
          ]),
          catchError(() => of(SetMessageReadMarkBulkError())),
        );
      }),
    ),
  );

  updateSelectedFolderOnAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkMessageAsReadSuccess, MarkMessageAsUnreadSuccess, SetMessageReadMarkBulkSuccess),
      switchMap(() => this.store.pipe(select(emailsReducer.getSelectedMenuItemId), take(1))),
      map((folderId) => GetCustomFolderStart({ folderId })),
    ),
  );

  getEmailsMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetEmailsMessageStart),
      mergeMap(action => this.emailsService.getMessageById(action.messageId).pipe(
        map(message => GetEmailsMessageSuccess({ message })),
        catchError(() => of(GetEmailsMessageError())),
      )),
    ),
  );

  saveMessageAsDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveMessageAsDraftStart),
      switchMap(action => this.emailsService.saveMessageAsDraft(action.message).pipe(
        switchMap(messageResponse => this.emailsService.getMessageById(messageResponse.id, true)),
        withLatestFrom(this.store.pipe(select(emailsReducer.getSelectedMenuItemId))),
        switchMap(([message, selectedMenuItem]) => {
          const actions: Action<string>[] = [];
          if (!action.silent) {
            actions.push(SetOpenedDraftMessage({ message }));
          }
          if (message.parentFolderId === selectedMenuItem && !action.message.id) {
            actions.push(
              GetCustomFolderStart({ folderId: message.parentFolderId }),
              ResetMessagesPagination({}),
            );
          }
          return actions;
        }),
        catchError(() => of(SaveMessageAsDraftError())),
      )),
    ),
  );

  sendOpenedDraftEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendOpenedDraftEmailStart),
      switchMap(action => this.emailsService.sendDraftMessage(action.message).pipe(
        tap(() => {
          this.alertify.success(this.translateService.instant('text.email.MessageSent'));
        }),
        switchMap(() => [
          SetOpenedDraftMessage({ message: null }),
          ResetMessagesPagination({}),
          RefreshCustomFoldersStart(),
        ]),
        catchError(() => of(SendOpenedDraftEmailError())),
      )),
    ),
  );

  removeEmailMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveEmailMessageStart),
      switchMap(action => {
        this.store.dispatch(RemoveEmailMessageSuccess({ messageId: action.message.id }));
        return this.emailsService.removeMessage(action.message.id).pipe(
          withLatestFrom(
            this.store.pipe(select(emailsReducer.getSelectedMessageId)),
            this.messagesPagination$,
          ),
          switchMap(([, selectedMessageId, pagination]) => {
            const subject = action.message.subject ? `"${action.message.subject}"` : '';
            this.alertify.success(this.translateService.instant('text.email.EmailMessageRemoved', { subject }));
            if (action.message.id === selectedMessageId) {
              this.emailsRoutingService.closeSelectedEmail().pipe(take(1)).subscribe();
            }
            const actions: Action<string>[] = [
              GetCustomFolderStart({ folderId: action.message.parentFolderId }),
              FetchMessagesRangeStart({ offset: pagination.offset + pagination.limit - 1, limit: 1 }),
            ];
  
            if (action.message.isDraft) {
              actions.push(SetOpenedDraftMessage({ message: null }));
            }
  
            return actions;
          }),
          catchError(() => of(RemoveEmailMessageError())),
        );
      }),
    ),
  );

  removeSelectedMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveSelectedEmailMessagesStart),
      switchMap(() => this.getBulkActionParams()),
      switchMap(({ selectedMessagesCount, selectedMessagesIds, unselectedMessagesIds, selectedFolderId, deletedEmailsSelected }) => {
        const confirmMessage = deletedEmailsSelected ? 'label.email.PermanentlyDeleteEmailsConfirmation' : 'label.email.DeleteEmailsConfirmation';
        return this.alertify.confirm$({
          message: this.translateService.instant(confirmMessage, { count: selectedMessagesCount }),
        }).pipe(
          switchMap(confirmed => {
            if (!confirmed) { return []; }
            this.store.dispatch(RemoveSelectedEmailMessagesSuccess({ selectedMessagesIds, unselectedMessagesIds }));
            return this.emailsService.removeMessagesBulk(selectedFolderId, selectedMessagesIds, unselectedMessagesIds).pipe(
              withLatestFrom(this.messagesPagination$),
              switchMap(([, pagination]) => {
                this.alertify.success(this.translateService.instant('text.email.EmailsMessagesRemoved', { count: selectedMessagesCount }));
                return [
                  GetCustomFolderStart({ folderId: selectedFolderId }),
                  FetchMessagesRangeStart({ offset: pagination.offset + pagination.limit - selectedMessagesCount, limit: selectedMessagesCount }),
                  ResetMessagesSelection(),
                ];
              }),
              catchError(() => of(RemoveSelectedEmailMessagesError())),
            );
          }),
        );
      }),
    ),
  );

  saveMessageToDms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveMessageToDmsStart),
      mergeMap(action => this.emailsService.saveMessageToDms(action.messageId, action.folderId).pipe(
        switchMap(() => this.store.pipe(select(emailsReducer.getMessageById(action.messageId))).pipe(filter(Boolean), take(1))),
        map((message) => {
          this.alertify.success(this.translateService.instant('text.email.MessageSavedToDms', { messageName: message.subject }));
          return SaveMessageToDmsSuccess();
        }),
        catchError(() => of(SaveMessageToDmsError())),
      )),
    ),
  );

  saveMessagesInFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveMessagesToDmsStart),
      switchMap(action => this.emailsService.saveMessageToDmsBulk(action.folderId, action.dmsFolderId, action.includedIds, action.excludedIds).pipe(
        switchMap(() => {
          if (action.dropped) {
            return of({ selectedMessagesCount: action.includedIds.length });
          }
          return this.getBulkActionParams();
        }),
        map(bulkParams => {
          this.alertify.success(this.translateService.instant('text.email.MessagesSavedInFolder', {
            itemsCount: bulkParams.selectedMessagesCount,
          }));
          return SaveMessagesToDmsSuccess();
        }),
        catchError(() => of(SaveMessagesToDmsError())),
      )),
    ),
  );

  saveMessageAttachmentsToDms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveMessageAttachmentsToDmsStart),
      switchMap(action => this.store.pipe(
        select(emailsReducer.getMessageById(action.messageId)),
        take(1),
        map(message => ({ message, folderId: action.folderId })),
      )),
      switchMap(({ message, folderId }) => this.emailsService.saveMessagesAttachmentsToDms(message.id, folderId).pipe(
        map(() => {
          this.alertify.success(this.translateService.instant('text.email.MessageAttachmentsSavedToDms', { messageName: message.subject }));
          return SaveMessageAttachmentsToDmsSuccess();
        }),
        catchError(() => of(SaveMessageAttachmentsToDmsError())),
      )),
    ),
  );

  moveMessagesToFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveMessagesToFolderStart),
      switchMap(({ messagesIds, folderId }) => {
        return forkJoin(messagesIds.map(messageId => this.emailsService.moveMessageToFolder(messageId, folderId)))
          .pipe(
            switchMap(messages => [
              MoveMessagesToFolderSuccess({ messages }),
              ResetMessagesPagination({}),
            ]),
            catchError(() => of(MoveMessagesToFolderError())),
          );
      }),
    ),
  );

  getMessageAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMessageAttachmentsStart),
      mergeMap(({ messageId }) => this.emailsService.getMessageAttachments(messageId).pipe(
        map(attachments => GetMessageAttachmentsSuccess({ messageId, attachments })),
        catchError(() => of(GetMessageAttachmentsError())),
      )),
    ),
  );

  uploadMessagesAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadMessageAttachmentsStart),
      switchMap(({ messageId, files }) => {
        return forkJoin(files.map(file => this.emailsService.uploadAttachment(messageId, file)))
          .pipe(
            map(attachments => UploadMessageAttachmentsSuccess({ messageId, attachments })),
            catchError(() => of(UploadMessageAttachmentsError())),
          );
      }),
    ),
  );

  uploadAttachmentsFromDms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadAttachmentsFromDmsStart),
      switchMap(({ messageId, files }) => {
        return forkJoin(files.map(file =>
          this.emailsService.uploadAttachmentFromDms(messageId, file.id).pipe(
            takeUntil(this.dmsFileUploadCanceled.pipe(
              filter(fileId => file.id === fileId),
            )),
          ),
        ))
          .pipe(
            map(attachments => UploadAttachmentsFromDmsSuccess({ messageId, attachments })),
            catchError(() => of(UploadAttachmentsFromDmsError())),
          );
      }),
    ),
  );

  cancelDmsattachmentUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelDmsAttachmentUpload),
      tap(action => this.dmsFileUploadCanceled.next(action.fileId)),
    ), { dispatch: false });

  removeMessagesAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveMessageAttachmentStart),
      mergeMap(({ messageId, attachmentId }) => this.emailsService.removeAttachment(messageId, attachmentId).pipe(
        map(() => RemoveMessageAttachmentSuccess({ messageId, attachmentId })),
        catchError(() => of(RemoveMessageAttachmentError())),
      )),
    ),
  );

  saveMessageAttachmentToDms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveMessageAttachmentToDmsStart),
      mergeMap(({ messageId, attachmentId, folderId }) =>
        this.emailsService.saveMessageAttachmentToDms(messageId, attachmentId, folderId).pipe(
          map((file: IrisDMSFileI) => {
            this.alertify.success(this.translateService.instant('text.email.AttachmentSavedInDMS', { name: file.name }));
            return SaveMessageAttachmentToDmsSuccess();
          }),
          catchError(() => of(SaveMessageAttachmentToDmsError())),
        ),
      ),
    ),
  );

  forwardMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForwardMessageStart),
      switchMap(action => this.emailsService.forwardMessage(action.messageId, action.message).pipe(
        withLatestFrom(this.store.pipe(select(emailsReducer.getSelectedMenuItemId))),
        switchMap(([messageResponse, selectedMenuItem]) => {
          const message = new IrisEmailMessage().fromResponse(messageResponse);
          const actions: Action<string>[] = [];
          if (!action.silent) {
            actions.push(SetOpenedDraftMessage({ message }));
          }
          if (messageResponse.parentFolderId === selectedMenuItem && !action.message.id) {
            actions.push(
              GetCustomFolderStart({ folderId: messageResponse.parentFolderId }),
              ResetMessagesPagination({}),
              GetMessageAttachmentsStart({ messageId: message.id }),
            );
          }
          return actions;
        }),
        catchError(() => of(ForwardMessageError())),
      )),
    ),
  );

  replyToMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReplyToMessageStart),
      switchMap(action => this.emailsService.replyToMessage(action.messageId, action.message).pipe(
        withLatestFrom(this.store.pipe(select(emailsReducer.getSelectedMenuItemId))),
        switchMap(([messageResponse, selectedMenuItem]) => {
          const message = new IrisEmailMessage().fromResponse(messageResponse);
          const actions: Action<string>[] = [];
          if (!action.silent) {
            actions.push(SetOpenedDraftMessage({ message }));
          }
          if (messageResponse.parentFolderId === selectedMenuItem && !action.message.id) {
            actions.push(
              GetCustomFolderStart({ folderId: messageResponse.parentFolderId }),
              ResetMessagesPagination({}),
            );
          }
          return actions;
        }),
        catchError(() => of(ReplyToMessageError())),
      )),
    ),
  );

  replyToAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReplyToAllStart),
      switchMap(action => this.emailsService.replyToAll(action.messageId, action.message).pipe(
        withLatestFrom(this.store.pipe(select(emailsReducer.getSelectedMenuItemId))),
        switchMap(([messageResponse, selectedMenuItem]) => {
          const message = new IrisEmailMessage().fromResponse(messageResponse);
          const actions: Action<string>[] = [];
          if (!action.silent) {
            actions.push(SetOpenedDraftMessage({ message }));
          }
          if (messageResponse.parentFolderId === selectedMenuItem && !action.message.id) {
            actions.push(
              GetCustomFolderStart({ folderId: messageResponse.parentFolderId }),
              ResetMessagesPagination({}),
            );
          }
          return actions;
        }),
        catchError(() => of(ReplyToAllError())),
      )),
    ),
  );

  getMessageConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetEmailsMessageConversationStart),
      switchMap(action => this.emailsService.getMessageConversation(action.conversationId).pipe(
        map(messages => messages.map(message => new IrisEmailMessage().fromResponse(message))),
        map(relatedMessages => GetEmailsMessageConversationSuccess({ messageId: action.messageId, relatedMessages })),
        catchError(() => of(GetEmailsMessageConversationError())),
      )),
    ),
  );

  fetchMessagesRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchMessagesRangeStart),
      withLatestFrom(
        this.store.pipe(select(emailsReducer.getSelectedMenuItemId)),
        this.store.pipe(select(emailsReducer.getMessagesPagination)),
        this.store.pipe(select(emailsReducer.getMessageTotalItemsCount)),
      ),
      filter(([{ offset }, selectedMenuItemId, , totalItemsCount]) =>
        !!selectedMenuItemId
        && (isNil(totalItemsCount) || offset <= totalItemsCount),
      ),
      switchMap(([
        action,
        selectedMenuItemId,
        pagination,
      ]) => {
        const { offset, limit } = action;
        const paginationRequest = { ...pagination, offset, limit };

        return this.emailsService.getMessagesByAlias(selectedMenuItemId, paginationRequest).pipe(
          switchMap(({ count, currentPage }) => {
            const actions: Action<string>[] = [
              AddEmailMessagesSuccess({ messages: currentPage, replace: false, offset, limit }),
            ];
            if (isNumber(count)) {
              actions.push(SetTotalItemsCount({ count }));
            }
    
            return actions;
          }),
          catchError(() => of(AddEmailMessagesSuccess({ messages: [], replace: false }))),
        );
      }),
    ),
  );

  emailsMessageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailsMessageChangeEvent),
      buffer(this.actions$.pipe(ofType(EmailsMessageChangeEvent), debounceTime(5000))),
      switchMap(events => forkJoin([
        of(events),
        this.store.pipe(select(emailsReducer.getSelectedMenuItemId), take(1)),
        this.messagesPagination$.pipe(take(1)),
      ])),
      switchMap(([actions, selectedMenuItemId, pagination]) => {
        const events = uniqWith<EmailMessageChangeEventI>(actions.map(action => action.event), isEqual);
        const uniqFoldersIds = uniq(events.map(event => event.parentFolderId).filter(Boolean));

        const actionsToDispatch: Action<string>[] = [
          GetUnreadCountStart(),
        ];
        const foldersReqestsLimit = 5;
        if (uniqFoldersIds.length > foldersReqestsLimit) {
          actionsToDispatch.push(RefreshCustomFoldersStart());
        } else {
          actionsToDispatch.push(...uniqFoldersIds.map(folderId => GetCustomFolderStart({ folderId })));
        }

        if (uniqFoldersIds.includes(selectedMenuItemId)) {
          const currentFolderEvents = events.filter(event => event.parentFolderId === selectedMenuItemId);
          currentFolderEvents.forEach(event => {
            switch(event.changeType) {
              case EmailMesssageChangeActionType.Deleted:
                actionsToDispatch.push(RemoveEmailMessageSuccess({ messageId: event.id }));
                break;
              case EmailMesssageChangeActionType.Created:
                actionsToDispatch.push(
                  FetchMessagesRangeStart({
                    offset: pagination.offset,
                    limit: pagination.limit,
                  }),
                );
                break;
              case EmailMesssageChangeActionType.Updated:
                actionsToDispatch.push(GetEmailsMessageStart({ messageId: event.id }));
                break;
            }
          });
        }

        return actionsToDispatch;
      }),
    ),
  );

  private readonly messagesPagination$ = this.store.pipe(select(emailsReducer.getMessagesPagination));

  private readonly dmsFileUploadCanceled = new EventEmitter<string>();

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<emailsReducer.EmailsCommonState>,
    private readonly emailsService: IrisEmailsService,
    private readonly alertify: IrisAlertService,
    private readonly translateService: TranslateService,
    private readonly emailsRoutingService: IrisEmailsRoutingService,
  ) {}

  private getBulkActionParams(): Observable<{
    checkAll: boolean;
    selectedMessagesIds: string[];
    unselectedMessagesIds: string[];
    selectedMessagesCount: number;
    selectedFolderId: string;
    deletedEmailsSelected: boolean;
  }> {
    return forkJoin([
      this.store.pipe(select(emailsReducer.getCheckAll), take(1)),
      this.store.pipe(select(emailsReducer.getSelectedMessagesIds), take(1)),
      this.store.pipe(select(emailsReducer.getDeselectedMessagesIds), take(1)),
      this.store.pipe(select(emailsReducer.getSelectedMessagesCount), take(1)),
      this.store.pipe(select(emailsReducer.getSelectedMenuItemId), take(1)),
      this.store.pipe(select(emailsReducer.getFolderByShortcut(EmailWellKnownFolderName.DeletedItems)), take(1)),
    ]).pipe(
      map(([checkAll, selectedMessagesIds, unselectedMessagesIds, selectedMessagesCount, selectedFolderId, deletedFolderId]) => {
        return {
          checkAll,
          selectedMessagesIds,
          unselectedMessagesIds,
          selectedMessagesCount,
          selectedFolderId,
          deletedEmailsSelected: selectedFolderId === deletedFolderId,
        };
      }),
    );
  }
}
