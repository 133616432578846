import { Component, ViewEncapsulation } from '@angular/core';
import { IrisUserService } from '@iris/common/services/user.service';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { IrisMsalService } from '@iris/modules/msal/msal.service';

@Component({
  selector: 'iris-header-profile-info',
  templateUrl: './header-profile-info.component.html',
  styleUrls: ['./header-profile-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IrisHeaderProfileInfoComponent  {

  get me(): IdentUserI {
    return this.userService.me;
  }

  constructor(
    private readonly userService: IrisUserService,
    private readonly authFacade: AuthFacade,
    private readonly msalService: IrisMsalService,
  ) { }

  logoff(): void {
    this.authFacade.logout(true);
    this.msalService.removeActiveAccount();
  }
}
