import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ColorIsDarkPipe,
  IrisDateAgoPipe,
  IrisDmsFilePreviewPipe,
  IrisFileSizePipe,
  IrisFilterFieldPipe,
  IrisHighlightTextPipe,
  IrisHighlightUrlPipe,
  IrisTransformPipe,
  IrisTranslatePipe,
  IrisUnitsPipe,
  SafeResourceUrlPipe,
  TruncatePipe,
} from './pipes';
import { IrisDateAgoPipeModule } from './pipes/date-ago/date-ago.module';
import { SafeHtmlPipeModule } from './pipes/dom-sanitizer/safe-html.module';
import { NgLatinizePipeModule } from './latinize/latinize.pipe';
import { FilterByPipe } from '@iris/common/modules/pipes-common/pipes/array-utils/filterBy.pipe';
import { ExcludePipe } from '@iris/common/modules/pipes-common/pipes/array-utils/exclude.pipe';

@NgModule({
  imports: [
    CommonModule,
    SafeHtmlPipeModule,
    IrisDateAgoPipeModule,
    NgLatinizePipeModule,
    IrisTranslatePipe,
    ExcludePipe,
    FilterByPipe,
  ],
  declarations: [
    IrisFilterFieldPipe,
    IrisFileSizePipe,
    IrisTransformPipe,
    IrisDmsFilePreviewPipe,
    IrisHighlightTextPipe,
    IrisHighlightUrlPipe,
    SafeResourceUrlPipe,
    TruncatePipe,
    ColorIsDarkPipe,
    IrisUnitsPipe,
  ],
  exports: [
    IrisFilterFieldPipe,
    IrisFileSizePipe,
    IrisTransformPipe,
    IrisTranslatePipe,
    IrisDmsFilePreviewPipe,
    IrisHighlightTextPipe,
    IrisHighlightUrlPipe,
    SafeResourceUrlPipe,
    TruncatePipe,
    ColorIsDarkPipe,
    IrisUnitsPipe,
    IrisDateAgoPipe,
  ],
  providers: [
    IrisFilterFieldPipe,
    IrisFileSizePipe,
    IrisTranslatePipe,
    IrisDmsFilePreviewPipe,
    IrisHighlightTextPipe,
    IrisHighlightUrlPipe,
    SafeResourceUrlPipe,
    TruncatePipe,
    ColorIsDarkPipe,
    IrisUnitsPipe,
  ],
})
export class IrisCommonPipesModule {
}
