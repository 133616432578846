<div class="modal-content-wrapper">
  @if (!!title || !!headerTitleTmpl) {
    <header class="modal-header bordered-bottom flex-valign">
      <div mat-dialog-title class="text-ellipsis">
        @if (!!headerTitleTmpl) {
          <ng-container [cdkPortalOutlet]="headerTitleTmpl"></ng-container>
        } @else {
          <span class="modal-title">{{ title }}</span>
        }
      </div>

      @if (enableResize) {
        <button
          class="btn btn-flat expand-button"
          [matTooltip]="(isExpanded ? 'label.Collapse' : 'label.Expand') | translate"
          (click)="onResize()"
          data-test="modal-header-expand-button">
          <i class="fal" [ngClass]="{'fa-compress': isExpanded, 'fa-expand': !isExpanded}"></i>
        </button>
      }
      @if (!isHideCross) {
        <button
          class="btn btn-flat close-button"
          [matTooltip]="'label.Close' | translate"
          data-test="modal-header-close-button"
          (click)="onClose($event)">
          <i [ngClass]="headerCloseIcon"></i>
        </button>
      }
    </header>
  }

  <section
    mat-dialog-content
    class="modal-body">
      <ng-content select="[id=iris-modal-body]"></ng-content>
  </section>

  <footer mat-dialog-actions class="modal-footer bordered-top flex-valign">
    <div class="left-side">
      <ng-content select="[id=iris-modal-footer-buttons-left]"></ng-content>
    </div>
    <div class="btn-toolbar">
      <ng-content select="[id=iris-modal-footer-buttons]"></ng-content>
      @if (!isHideCloseBtn) {
        <button
          type="button"
          class="btn btn-default"
          data-test="modal-footer-close-button"
          (click)="onClose($event)">
          {{ cancelBtnText | translate }}
        </button>
      }
    </div>
  </footer>
</div>
