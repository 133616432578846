import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, DoCheck,
  EventEmitter,
  forwardRef, Host,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IrisFieldBaseComponent } from '../field-base/field-base.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IrisFieldTextTranslationsModalComponent } from './field-text-translations-modal/field-text-translations-modal.component';
import { IrisLanguageI } from '../../../models/IrisLanguage';
import { take, tap } from 'rxjs/operators';
import merge from 'lodash/merge';
import { ValidationMessageResolverService } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver.service';
import { EnhancedControlDirective } from '@iris/common/modules/fields/base-control/enhanced-control.component';

/**
 * This component has been @deprecated since we migrated to the new Confirm Modal Component.
 * When calling the confirmation dialog, make sure that the isLegacy flag in your component is not set to 'true'.
 */

@Component({
  selector: 'iris-field-text',
  templateUrl: './field-text.component.html',
  styleUrls: ['./field-text.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IrisFieldTextComponent),
    multi: true,
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [{
    directive: EnhancedControlDirective,
    inputs: ['stylingMode'],
  }],
})
export class IrisFieldTextComponent extends IrisFieldBaseComponent implements ControlValueAccessor, DoCheck {

  dialogRef: MatDialogRef<IrisFieldTextTranslationsModalComponent>;

  @Input() languages: IrisLanguageI[] = undefined;
  @Input() translations: unknown;
  @Input() showClear = false;
  @Input() hideTranslationText = true;
  @Input() inputType = 'text';
  @Input() showSuffix = true;
  @Input() translatedFieldName: string;
  @Input() appearance = 'fill';
  @Input() placeholder: string;
  @Input() pattern: string | RegExp;

  @Output() translationsChange: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<unknown>,
    @Optional() readonly formControl: FormControl,
    @Optional() private readonly validationMessageResolver: ValidationMessageResolverService,
    changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    @Host() public readonly enhancedControl: EnhancedControlDirective,
  ) {
    super(merge(validators || [], formControl?.validator ? [formControl.validator] : []), formControl, changeDetector);
  }

  getValidationMessage(): string {
    return this.validationMessageResolver.getErrorMessage(this.valueControl);
  }

  openTranslationModal(): void {
    const dialogRef = this.dialog.open(
      IrisFieldTextTranslationsModalComponent, {
        data: {
          languages: this.languages,
          translatedFieldName: this.translatedFieldName,
          translations: this.translations,
        },
        maxWidth: '500px',
      });
    dialogRef.componentInstance.onSetTranslations.pipe(
      take(1),
      tap((translations) => this.translationsChange.emit(translations)),
    ).subscribe();
  }

  clearValue(event: Event): void {
    event.stopPropagation();
    if (!this.valueControl.touched) {
      this.valueControl.markAsTouched();
    }
    this.valueControl.setValue(null);
  }

  reset(): void {
    this.valueControl.reset();
    this.valueControl.setErrors(null);
  }

  ngDoCheck(): void {
    if (this.formControl?.touched) {
      this.valueControl.markAsTouched();
    }
  }
}
