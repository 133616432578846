import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { IrisLoaderService } from '@iris/common/services/loader.service';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { MicroservicesUtils } from '@iris/common/utils/url.utils';
import {
  DocumentValidationExceptionI,
  isDocumentValidationException,
} from '@iris/common/modules/dynamic-forms/df-form/features/validation-helpers/document-validation-exception';

export const IGNORED_STATUSES = new HttpContextToken<number[]>(() => []);

export class IrisErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private readonly translateService: TranslateService,
    private readonly loader: IrisLoaderService,
    private readonly alertify: IrisAlertService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const ignoredStatuses = req.context.get(IGNORED_STATUSES);
    return next.handle(req).pipe(catchError(err => this.handleError(err, ignoredStatuses)));
  }

  private handleError(error: HttpErrorResponse, ignoredStatuses: number[] = []): Observable<never> {
    if (ignoredStatuses.includes(error.status)) {
      return throwError(() => error);
    }
    if (isDocumentValidationException(error.error)) {
      return throwError(() => this._setMessageToError(error, (error.error as DocumentValidationExceptionI).translatedMessage));
    }

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.message || error.error.message);
    } else {
      this.loader.stop();
      let mes;
      switch (error.status) {
        case 0:
          for (const microservice in MicroservicesUtils.MICROSERVICES) {
            if (error.url.indexOf('/restful/' + microservice) >= 0) {
              if (MicroservicesUtils.MS_WITH_ERROR_NOTIFICATION.includes(microservice)) {
                mes = this.translateService.instant('error.Integrations.ServiceIsNotAvailable', { service: microservice });
                this.alertify.error(mes);
                return throwError(() => this._setMessageToError(error, mes));
              }
              console.warn('Request error ', ' (Error code: ' + error.status + ', microservice unavailable: ' + microservice + ')');
              break;
            }
          }
          break;
        case HttpStatusCode.BadRequest:
          mes = this.translateError(error.error);
          this.alertify.error(mes);
          return throwError(() => this._setMessageToError(error, mes));
        case HttpStatusCode.Forbidden:
          mes = this.translateService.instant('text.AccessDeniedError');
          this.alertify.error(mes);
          return throwError(() => this._setMessageToError(error, mes));
        case HttpStatusCode.NotFound:
          return throwError(() => this._setMessageToError(error, mes));
        case HttpStatusCode.Conflict:
        case HttpStatusCode.PreconditionFailed:
        case HttpStatusCode.Locked:
        case HttpStatusCode.FailedDependency:
        case HttpStatusCode.PreconditionRequired:
          mes = this.translateError(error.error);
          this.alertify.error(mes);
          return throwError(() => this._setMessageToError(error, mes));
        case HttpStatusCode.InternalServerError:
          mes = error.error?.messages?.length
            ? this.translateError(error.error)
            : this.translateService.instant('text.ServerInternalError');
          return throwError(() => this._setMessageToError(error, mes));
        case HttpStatusCode.NotImplemented:
        case HttpStatusCode.BadGateway:
        case HttpStatusCode.ServiceUnavailable:
          mes = error.error?.messages?.length
            ? this.translateError(error.error)
            : this.translateService.instant('text.ServerInternalError');
          this.alertify.error(mes);
          return throwError(() => this._setMessageToError(error, mes));
        default:
          this.alertify.error('Request error ' + error.error?.message || error.message + ' (Error code: ' + error.status + ')');
          return throwError(() => this._setMessageToError(error, mes));
      }
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(() => this._setMessageToError(error, 'Something bad happened; please try again later.'));
  }

  private translateError(data): string {
    const errorMessages = data?.messages || data?.errors?.message || data?.errors?.validation || [];
    if (errorMessages.length) {
      // common format support
      const args = data.args || {};
      for (const key in args) {
        if (/^\d+$/.test(key)) {
          Object.defineProperty(args, 'p' + key, Object.getOwnPropertyDescriptor(args, key));
          delete args[key];
        }
      }
      return this.translateService.instant(errorMessages[0], args);
    }

    // form validation format support
    if (data?.details?.length) {
      const args = data.details[0].args || {};
      return this.translateService.instant(data.details[0].code, args);
    }

    return this.translateService.instant('text.RequestError');
  }

  _setMessageToError(error: HttpErrorResponse, message: string): HttpErrorResponse {
    return {
      ...error,
      message,
    };
  }
}
