import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { API_KEY } from './api-key.token';

@NgModule({
  imports: [],
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ],
})
export class IrisGoogleMapsIntegrationModule {
  static forRoot(apiKeyProviderOptions: { useFactory: (...args: any) => string; deps: any[] }): ModuleWithProviders<IrisGoogleMapsIntegrationModule> {
    return {
      ngModule: IrisGoogleMapsIntegrationModule,
      providers: [
        { provide: API_KEY, useFactory: apiKeyProviderOptions.useFactory, deps: apiKeyProviderOptions.deps },
      ],
    };
  }
}
